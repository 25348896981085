import React, { useEffect, useState, useRef } from "react";
import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";
import { PageContent } from "../../../components/PageComponent";
import api from "../../../config/configApi";
import { Link, useLocation } from "react-router-dom";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "datatables.net-bs5";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export const Servico = () => {
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [status, setStatus] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : "",
  });


  const dataTableRef = useRef(null);

  const permissao = JSON.parse(localStorage.getItem("permissao"));

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];

  const getServico = async () => {
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    await api
      .get("/servico", headers)
      .then((response) => {
        if (
          dataTableRef.current &&
          $.fn.DataTable.isDataTable(dataTableRef.current)
        ) {
          $(dataTableRef.current).DataTable().destroy();
        }
        console.log("servicos", response);
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            order: [[0, "desc"]],
          });
        }
      })
      .catch((err) => {
        setStatus({
          type: "error",
          mensagem: "Erro: Tente mais tarde!",
        });
      });
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const agruparPorArea = (lista) => {
    const grupos = {};

    lista.forEach((item) => {
      if (!grupos[item.area]) {
        grupos[item.area] = [];
      }

      grupos[item.area].push(item);
    });

    return grupos;
  };

  const gerarPdf = () => {
    const dataAgrupada = agruparPorArea(data);

    const docDefinition = {
      content: [
        { text: 'Lista de Serviços', style: 'header' },
        { text: '\n' },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10]
        }
      }
    };

    Object.keys(dataAgrupada).forEach((area) => {
      docDefinition.content.push(
        { text: `Área: ${area}`, style: 'areaHeader' },
        {
          table: {
            headerRows: 1,
            body: [
              ['ID', 'Tipo', 'Serviço'],
              ...dataAgrupada[area].map(item => [item.id, item.tipo, item.servico])
            ]
          }
        },
        { text: '\n' }
      );
    });

    pdfMake.createPdf(docDefinition).download('lista_de_servicos.pdf');
  };

  //   const deleteNivel = async (idNivel) => {
  //     if (window.confirm('Tem certeza que deseja excluir este registro?')) {

  //       const headers = {
  //         'headers': {
  //           'Authorization': "Bearer " + localStorage.getItem('token')
  //         }
  //       }

  //       await api.delete("/typeuser/" + idNivel, headers)
  //         .then((response) => {
  //           console.log("response excluir", response);
  //           getNiveis();
  //           setStatus({
  //             type: 'redSuccess',
  //             mensagem: response.data.mensagem
  //           });
  //         }).catch((err) => {
  //           if (err.response) {
  //             console.log("response", err.response);
  //           } else {
  //             console.log("'Erro: Tente mais tarde!");
  //           }
  //         })
  //     }

  //   }

  useEffect(() => {
    getServico();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container">
          <h1>Serviços</h1>
          {status.type === "success" ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{status.mensagem}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}

          <Link to={"/addservico"}>
            <button type="button" className="btn btn-primary me-md-2">
              <i className="fa-solid fa-plus"></i>Adicionar
            </button>
          </Link>

          {Adicionar.map((item) => (
            <Link to={"/addservico"}>
              <button type="button" className="btn btn-primary me-md-2">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}
          
          <button type="button" className="btn btn-primary" onClick={gerarPdf}>
            <i className="fa-solid"></i>Gerar pdf
          </button>

          <br></br>
          <br></br>
          <div className="card mt-5">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  ref={dataTableRef}
                  className="table table-fit mt-5 table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Serviço</th>
                      <th scope="col">area</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody id="pdf-content">
                    {data.map((servicos) => (
                      <tr key={servicos.id}>
                        <th scope="row">{servicos.id}</th>
                        <td>{servicos.servico}</td>
                        <td>{servicos.area}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              <Link to={"/viewservico/" + servicos.id}>
                                <button
                                  type="button"
                                  className="btn btn-warning me-md-2"
                                >
                                  <i className="fa-solid fa-eye"></i>Visualizar
                                </button>
                              </Link>
                              {View.map((item) => (
                                <Link to={"/viewservico/" + servicos.id}>
                                  <button
                                    type="button"
                                    className="btn btn-warning me-md-2"
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                    Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              <Link to={"/editarservico/" + servicos.id}>
                                <button
                                  type="button"
                                  className="btn btn-success me-md-2"
                                >
                                  <i className="fa fa-edit"></i>Editar
                                </button>
                              </Link>
                              {Editar.map((item) => (
                                <Link to={"/editarservico/" + servicos.id}>
                                  <button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                  >
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  {/* <button type="button" onClick={() => deleteNivel(servicos.id)} className="btn btn-danger">
                                                                        <i className="fa-solid fa-trash"></i>
                                                                        Deletar
                                                                    </button> */}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
};
