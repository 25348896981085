import React, { useState, useEffect } from "react";
import { format, parseISO, parse } from "date-fns";
import { zonedTimeToUtc } from 'date-fns-tz';
import api from "../../../config/configApi";
import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";
import { PageContent } from "../../../components/PageComponent";
import { Link, Redirect } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logoReduto from '../../../assets/img/logoReduto.jpg';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const AddOrcamento = () => {
  const [retorno, setRetorno] = useState({
    type: "",
    mensagem: "",
  });

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const vendedor = localStorage.getItem("nome");
  const idEmpresa = localStorage.getItem("empresa");
  const dataFormatacaoAntiga = format(new Date(), 'dd/MM/yyyy');
  const dataAtual = new Date();

  var znDateDate = zonedTimeToUtc(dataAtual, 'America/Sao_Paulo');
  var dataDataAtualConvertida = format(znDateDate, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleGeneratePDF = async () => {
    await getBanco(dadosBancariosSelecionado);
    const cliente = clientes.find((cliente) => cliente.id == clienteSelecionado);
    console.log("cliente", cliente);
    var dataPagamentoFormatado;
    if (Pagamento.datapagamento) {
      dataPagamentoFormatado = format(parse(Pagamento.datapagamento, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
    }


    var produtosSemDesconto = `${produtos.totalSemDesconto.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;

    var convertePagamentoTotal = parseFloat(Pagamento.total);

    var produtoTotal = `${convertePagamentoTotal.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;

    var converteValorParcela = parseFloat(Pagamento.valorParcela);

    var pagamentoValorParcela = `${converteValorParcela.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
    })}`;




    const clienteInfo = cliente.tipoCliente === "PL"
      ? `CPF: ${cliente.cpf ? `${cliente.cpf}\n` : "\n"}`
      : `CNPJ: ${cliente.cnpj ? `${cliente.cnpj}\n` : "\n"}`;
    const documentDefinition = {
      content: [
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 4,
              lineColor: '#0e5a5c',
            },
          ],
          margin: [0, 10, 0, 10],
        },
        // {
        //   text: `${nome}`,
        //   style: "header",
        // },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `${nome}\n`,
                `Cnpj: ${cnpj}\n`,
                `Email: ${email}\n`,
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [
                `Tel: ${telefone}\n`,
                `${razaoSocial}\n`,
              ],
            }
          ]
        },
        {
          text: "Orçamento",
          style: "subheaderCustom",
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [`Data:\n ${dataFormatacaoAntiga}\n`],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Vendedor:\n ${vendedor}\n`],
            }
          ]
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: '#000000',
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                {
                  text: [
                    `Para: \n`,
                    `${cliente.nome}\n`,
                    `${cliente.endereco ? `${cliente.endereco}\n` : "\n"}`,
                    `Tel: ${cliente.telefone ? `${cliente.telefone}\n` : "\n"}`,
                    `Cep: ${cliente.cep ? `${cliente.cep}\n` : "\n"}`,
                    clienteInfo,
                    `Aos cuidados de: ${formData.aosCuidados ? `${formData.aosCuidados}\n` : "\n"}`,
                  ],
                },
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Validade:\n ${formData.validade} dias \n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: '#000000',
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          text: "Serviços",
          style: "subheader",
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 'auto', 45, 'auto'], // Ajuste os valores conforme necessário
            body: [
              ["Serviço", "Descrição", "Área", "Quantidade", "Valor", "Subtotal"],
              ...listaServicos.map((servico) => [
                servico.servico,
                servico.descricao,
                areaOptions.find((item) => item.id == servico.area_id).area,
                servico.quant,
                `R$ ${servico.valor}`,
                `R$ ${servico.subtotal}`,
              ]),
            ],
          },
        },
        {
          // Verifica se detalhes não é vazio nem indefinido
          ... (formData.introducao !== '' && formData.introducao !== undefined) ?
            {
              canvas: [
                {
                  type: "line",
                  x1: 0,
                  y1: 0,
                  x2: 510,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: "#000000",
                },
              ],
              margin: [0, 10, 0, 10],
            }
            : null, // Se detalhes for vazio ou indefinido, não mostra nada
        },
        {
          ... (formData.introducao !== '' && formData.introducao !== undefined) ? {
            text: "Detalhes",
            style: "subheader",
          }
            : null,
        },
        {
          text: formData.introducao || '', // Se detalhes for vazio ou indefinido, exibe uma string vazia
          alignment: 'justify' // ou 'left', 'right', 'center'
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        formaPagamento === "avista" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "À vista\n",
              { text: "Total sem desconto: ", bold: true }, `${produtosSemDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${produtos.desconto}%\n`,
              { text: "Total: ", bold: true }, `${produtoTotal}\n`,
              { text: "Data de pagamento: ", bold: true }, `${dataPagamentoFormatado}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        formaPagamento === "parcelado" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "parcelado\n",
              { text: "Total sem desconto: ", bold: true }, `${produtosSemDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${produtos.desconto}%\n`,
              { text: "Total: ", bold: true }, `${produtoTotal}\n`,
              { text: "Parcelas: ", bold: true }, `${Pagamento.numeroparcela}\n`,
              { text: "Valor parcela: ", bold: true }, `${pagamentoValorParcela}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        ...(formaPagamento === "parcelado" ? [
          {
            text: "Lista de Parcelas",
            style: "subheader",
          },
          {
            table: {
              body: [
                ["Data de pagamento", "Valor da parcela"],
                ...datasParceladas.map((parcelas) => [
                  parcelas.datapagamento,
                  `${pagamentoValorParcela}`,
                ]),
              ],
            },
          },
        ] : []),
        {
          style: "normalText",
          text: [
            { text: "Dados bancários: ", bold: true }, `\n`,
            { text: "Banco: ", bold: true }, `${banco}\n`,
            { text: "Agência: ", bold: true }, `${agencia}\n`,
            { text: "Número da conta: ", bold: true }, `${conta}\n`,
            { text: "Tipo da conta: ", bold: true },
            `${tipoconta === "contacorrente" ? "Conta corrente" : tipoconta}\n`,
          ]
        },
        { text: '', pageBreak: 'after' },
        {
          width: "100%",
          text: [
            { text: 'Condições Comerciais:', bold: true, alignment: 'justify' }, // Título em negrito e justificado
            '\n', // Espaço após o título
            // Parágrafos justificados
            { text: "Validade do Orçamento: Este orçamento é válido por até 15 dias a partir da data de emissão.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Prazo de Entrega e Execução dos Serviços: O prazo de entrega ou execução dos serviços é de 30 dias a partir da aprovação da proposta, desde que dentro do período de validade. Os relatórios disponibilizados na plataforma online serão entregues em até 7 dias úteis após a execução das medições in loco.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Pagamento: O pagamento deverá ser realizado em até 7 dias úteis a partir do encerramento do atendimento in loco. O preço listado inclui os tributos vigentes na data da proposta. Em caso de não aceitação da proposta após visita para levantamento de dados, a taxa de visita será faturada.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Revisão de Preços e Tributos: Reservamo-nos o direito de oferecer alternativas técnicas que possam implicar em alteração dos valores. Qualquer alteração nos tributos vigentes resultará na revisão do preço ofertado.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Mora e Multa por Atraso de Pagamento: O atraso no pagamento incorrerá em juros de mora de 1% ao mês, multa moratória de 10%, além de indenização pré-fixada com base na variação do CDI entre a data de vencimento e a de efetivo pagamento.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Cobrança Judicial ou Extrajudicial: Em caso de cobrança judicial ou extrajudicial, o cliente será responsável por reembolsar todas as perdas e danos, incluindo despesas decorrentes das medidas de cobrança.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Responsabilidade do Cliente e da Contratada:\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente é responsável por garantir o acesso da Contratada às suas instalações e equipamentos necessários para a realização dos serviços contratados.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente assume total responsabilidade por falhas apresentadas pelos equipamentos durante as aferições, não cabendo à Contratada o conserto de peças danificadas. A Contratada não realiza reparos em equipamentos submetidos a testes e aferições.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "A Contratada é legalmente responsável pelos relatórios emitidos, garantindo o uso de padrões e medidores internacionalmente aceitos conforme as normativas e legislações aplicáveis em âmbito nacional.\n", margin: [0, 5, 0, 0], alignment: 'justify' }
          ],
          style: "observaoText",
        },
        listaServicos.some(servico => servico.area_id === "1") ? [
          {
            width: "100%",
            stack: [
              {
                text: [
                  { text: 'Observações Técnicas:', bold: true }, // Adiciona negrito apenas ao título
                  '\n', // Adiciona um espaço para separar o título do texto
                  "Os resultados dos testes serão apresentados em forma de relatórios, com os dados dos equipamentos, das instalações, resultados e conclusões.\n" +
                  "Os referidos relatórios terão validade de acordo com a legislação atual RDC 611 e suas respectivas Instruções Normativas (IN 90 – IN 97).\n" +
                  "O valor proposto é para realização dos testes em visita única, em caso de revisita para testes em equipamentos que no dia agendado não estavam aptos para o funcionamento, ou para novos testes em itens que não estavam satisfatórios, um novo orçamento deverá ser solicitado.\n" +
                  "Para a execução dos testes, pedimos que cada sala seja reservada durante 1h30.\n" +
                  "O sistema utilizado para gerar as imagens radiográficas do serviço deve estar em funcionamento para que sejam avaliadas.\n" +
                  "Para a realização dos testes é necessário a presença de um técnico de Raios X, que esteja habituado com as rotinas de exames da instituição, para orientar e acompanhar os serviços.\n" +
                  "Para testes em tomografia computadorizada é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "Para testes em ressonância magnética nuclear é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "As importâncias discriminadas já incluem os gastos de locomoção (ida e volta) entre nosso estabelecimento e as instalações onde se encontram os equipamentos.\n" +
                  "Caso os serviços não sejam realizados por culpa exclusiva da CONTRATANTE, será cobrada uma taxa de 20% do valor do serviço para fins de ressarcimento dos custos de deslocamento do físico.",
                ],
                margin: [0, 5], // Ajusta a margem superior para evitar espaçamento extra
                alignment: 'justify', // Justifica o texto
              },
            ],
            style: "observaoText",
          },
        ] : [],
        {
          text: " ",
          style: "subheader",
          margin: [0, 40, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              stack: [
                {
                  text: "Contratante",
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
                {

                  text: `${cliente.nome}\n`,
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  text: `${nome}`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
                {
                  text: `${vendedor}`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
              ],
            },
          ],
        },

      ],
      header: [{
        image: logoReduto, width: 30, alignment: 'left', margin: [30, 15, 0, 10],
      }],
      footer: function (currentPage, pageCount) {
        let footerContent = {
          margin: [40, 0, 40, 30], // Ajuste as margens conforme necessário
          stack: []
        };

        // Se não for a última página, mostre a linha com o número da página
        //if (currentPage !== pageCount) {
        footerContent.stack.push(
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 510, // Ajuste o comprimento da linha conforme necessário
                y2: 0,
                lineWidth: 2,
                lineColor: "#0e5a5c",
              },
            ],
            margin: [0, 0, 0, 10], // Ajuste as margens para posicionar a linha
            // Número da página
          },
          {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'right',
            fontSize: 9, // Ajuste o tamanho da fonte conforme necessário
            margin: [0, 10, 0, 0] // Margem superior para o número da página
          }
        );
        //}

        return footerContent;
      },
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
          color: "#0e5a5c",
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        subheaderCustom: {
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
          color: '#0e5a5c'
        },
        normalText: {
          fontSize: 12,
          margin: [0, 10, 0, 5],
        },
        observaoText: {
          fontSize: 10,
          margin: [0, 10, 0, 5],
        },
      },
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  const [formData, setFormData] = useState({
    cliente: "",
    vendedor: vendedor,
    data: dataDataAtualConvertida, // Data atual formatada no padrão brasileiro
    aosCuidados: "",
    validade: 15,
    canalVenda: "",
    centroCustos: "",
    introducao: "",
    observacaoesgerais: "",
    observacoesInternas: ""
  });

  const [servicos, setServicos] = useState({
    servico: "",
    detalhes: "",
    descricao: "",
    quant: 1,
    valor: 0,
    desconto: 0,
    subtotal: 0,
    idarea: "",
    modelo: "",
    periodo: "Mensal"
  });

  const [servicoSelecionado, setServicoSelecionado] = useState({
    descricao: "",
    valor: 0,
    servico: "",
    area_id: "",
    id_servico: "",
    modelo: "",
    periodo: "Mensal"
  });

  const [listaServicos, setListaServicos] = useState([]);

  const [produtos, setProdutos] = useState({
    quantidade: 0,
    desconto: 0,
    descontoPercentual: 0,
    totalSemDesconto: 0,
    total: 0,
  });

  const [Pagamento, setPagamento] = useState({
    formapagamento: "",
    valorVista: 0,
    numeroparcela: 0,
    valorParcela: 0,
    totalSemDesconto: 0,
    total: 0,
    datapagamento: "",
    dadosbancarios: "",
    datapagamentoparcelado: "",
    dadosbancariosparcelado: ""
  });

  const [clienteSelecionado, setClienteSelecionado] = useState("");
  const [area_id, setAreaId] = useState("");
  const [valorServico, setValorServico] = useState('');
  const [verificaFormaPagamento, setVerificaFormaPagamento] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [dadosBancariosSelecionado, setDadosBancariosSelecionado] = useState("");
  const [datasParceladas, setDatasParceladas] = useState([]);
  const [selectedValueTreinamentos, setSelectedValueTreinamentos] = useState('');

  const handleDataChange = (e) => {
    Pagamento.datapagamentoparcelado = e.target.value;
    setPagamento({
      ...Pagamento,
      [e.target.name]: e.target.value,
    });
  };

  const formatarDataBrasileira = (data) => {
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
  };

  const handleDatasParcelamento = () => {
    console.log("pagamento", Pagamento);
    console.log("Pagamento.datapagamentoparcelado", Pagamento.datapagamentoparcelado);
    const data = new Date(Pagamento.datapagamentoparcelado);
    const datas = [];
    const novaDataPagamento = new Date(Pagamento.datapagamentoparcelado);
    const novaDataPagamentoFormat = formatarDataBrasileira(novaDataPagamento.toISOString().slice(0, 10));
    const objDataPagamento = {
      datapagamento: novaDataPagamentoFormat,
      valorParcela: Pagamento.valorParcela
    };
    datas.push(objDataPagamento);

    for (let i = 1; i < Pagamento.numeroparcela; i++) {
      console.log("i", i);
      const novaData = new Date(data);
      novaData.setMonth(data.getMonth() + i);
      console.log("data", novaData);
      const dataFormatada = formatarDataBrasileira(novaData.toISOString().slice(0, 10));
      const obj = {
        datapagamento: dataFormatada,
        valorParcela: Pagamento.valorParcela
      }
      datas.push(obj);
    }

    console.log("datas", datas);
    setDatasParceladas(datas);
    console.log("datasParceladas", datasParceladas);
  };

  useEffect(() => {
    console.log("datasParceladas", datasParceladas);
  }, [datasParceladas]);

  const handleChange = (e) => {
    if (e.target.name === "cliente") {
      setClienteSelecionado(e.target.value);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDataPagamento = (e) => {
    console.log('e dataPagamento', e);
    Pagamento.datapagamento = e.target.value;
    setPagamento({
      ...Pagamento,
      [e.target.name]: e.target.value,
    });
  };

  const handleDadosBancarios = async (e) => {
    console.log("dados bancarios", e);
    setDadosBancariosSelecionado(e.target.value);
    await getBanco(e.target.value);
  };

  const handleAreaChange = (e) => {
    getServicos(e.target.value);
    setAreaId(e.target.value);
  };

  const handleChangeTreinamento = (e) => {
    setSelectedValueTreinamentos(e.target.value);
  };

  const handleServicosSelectedChange = (e) => {
    const { name, value } = e.target;
    const servicoDaApi = servicosDaApi.find(
      (servico) => servico.id === parseInt(value)
    );
    console.log("SERVIÇO", value);
    setValorServico(value);

    setServicoSelecionado({
      descricao: servicoDaApi ? servicoDaApi.descricao : "",
      valor: servicoDaApi.valor != undefined ? servicoDaApi.valor : "1",
      servico: servicoDaApi ? servicoDaApi.servico : "",
      area_id: area_id,
      id: servicoDaApi.id,
    });

    if (value === "48") {
      getTreinamentoPorArea();
    };
  };

  const handleServicosChange = (e) => {
    //console.log("e entrou", e.target.value);
    const { name, value } = e.target;
    console.log("value", value);
    console.log("name", name);

    setServicos((prevServicos) => {
      //console.log("servicoSelecionado.valor", servicoSelecionado.valor);

      //console.log("prevServicos.valor", prevServicos.valor);
      let valor = name === "valor" ? value : prevServicos.valor;
      let descricao = name === "descricao" ? value : prevServicos.descricao;
      let quant = name === "quant" ? value : prevServicos.quant;
      let desconto =
        name === "desconto" ? value : parseFloat(prevServicos.desconto);
      if (desconto === undefined) {
        desconto = 0;
      }

      let subtotal = (quant * valor) - desconto;

      servicoSelecionado.valor = valor;
      servicoSelecionado.descricao = descricao;
      servicoSelecionado.area_id = area_id;

      //console.log("area_id", area_id);
      //console.log("servicoSelecionado", servicoSelecionado);

      return {
        ...prevServicos,
        [name]: value,
        subtotal: subtotal,
      };
    });

    console.log("area", area_id);
    console.log("value", value);
    if (value === "Programa de Educação Continuada" && name === "descricao") {
      getTreinamentoPorArea();
    };
    if (value !== "Programa de Educação Continuada" && name === "descricao") {
      setTreinamentoOptions([]);
      setSelectedValueTreinamentos("");
    };
  };

  const getTreinamentoPorArea = async () => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      const response = await api.post("/treinamentosPorArea", { area_id }, headers);
      console.log("treinamentosPorArea", response.data);
      setTreinamentoOptions(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de vinculo2:", error);
    }

  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handlePagamentoParcela = (e) => {
    //console.log("e entrou", e.target.value);
    const { name, value } = e.target;
    console.log("value", value);
    console.log("name", name);
    console.log("Entrou Pagamento", Pagamento);
    console.log(produtos);

    if (name === "numeroparcela") {
      console.log("Entrou");
      let valorParcelo = (Pagamento.total) / value;
      Pagamento.numeroparcela = parseInt(value);

      setPagamento({
        formapagamento: formaPagamento,
        valorVista: 0,
        numeroparcela: Pagamento ? Pagamento.numeroparcela : "",
        total: Pagamento ? produtos.total : "",
        valorParcela: Pagamento ? valorParcelo.toFixed(2) : "",
        datapagamentoparcelado: Pagamento ? Pagamento.datapagamentoparcelado : "",
        totalSemDesconto: Pagamento ? produtos.totalSemDesconto : "",
      });
    }



  };

  const [empresa, setEmpresa] = useState([]);
  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");


  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [tipoconta, setTipoConta] = useState("");

  const getEmpresa = async () => {
    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.get("/empresa/" + idEmpresa, headers)
      .then((response) => {
        if (response.data) {
          setNome(response.data.nome);
          setCnpj(response.data.cnpj);
          setEmail(response.data.email);
          setTelefone(response.data.telefone);
          setRazaoSocial(response.data.razaoSocial);

        } else {
          console.log("Erro: Usuário não encontrado !");
        }

      }).catch((err) => {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro  não encontrado");
        }
      })
  }

  const getBanco = async (dadosBancariosSelecionado) => {
    console.log("dadosBancariosSelecionado", dadosBancariosSelecionado);
    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.get("/selecionarbanco/" + dadosBancariosSelecionado, headers)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data) {

          setBanco(response.data.banco);
          setAgencia(response.data.agencia);
          setConta(response.data.conta);
          setTipoConta(response.data.tipoconta);

        } else {
          console.log("Erro: Usuário não encontrado !");
        }

      }).catch((err) => {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro  não encontrado");
        }
      })
  }

  const handleAdicionarServico = (e) => {




    console.log("area_id", area_id);
    console.log("servicoSelecionado.servico", servicoSelecionado.servico);
    const camposObrigatorios = [
      { nome: "Área", valor: area_id },
      { nome: "Serviço", valor: servicoSelecionado.servico },
      { nome: "Descrição", valor: servicoSelecionado.descricao },
      { nome: "Quantidade", valor: servicos.quant },
      { nome: "Valor", valor: servicoSelecionado.valor },
      { nome: "Modelo", valor: servicoSelecionado.modelo },
    ];

    const camposFaltando = camposObrigatorios.filter(campo =>
      campo.valor === undefined || campo.valor === "" || campo.valor === null
    );

    if (camposFaltando.length > 0) {
      const mensagemErro = camposFaltando.map(campo => `${campo.nome} é obrigatório.`).join("\n");
      if (!area_id || !servicoSelecionado.descricao || !servicoSelecionado.servico || !servicos.quant || !servicoSelecionado.valor || !servicoSelecionado.modelo) {
        let missingFields = [];

        if (!servicoSelecionado.servico) missingFields.push("Serviço");
        if (!servicoSelecionado.descricao) missingFields.push("Descrição");
        if (!servicos.quant) missingFields.push("Quantidade");
        if (!servicoSelecionado.valor) missingFields.push("Valor");
        if (!servicoSelecionado.modelo) missingFields.push("Modelo");
        if (!area_id) missingFields.push("Área");
        if (servicoSelecionado.descricao === "Programa de Educação Continuada" && !selectedValueTreinamentos) missingFields.push("Treinamento");


        setModalMessage(`Os seguintes campos estão faltando: ${missingFields.join(", ")}`);
        setIsModalOpen(true);
        return;
      }
      return;
    }
    e.preventDefault();

    console.log("servicoSelecionado", selectedValueTreinamentos);
    //console.log("selectedPeriod", treinamentoPorArea);
    var treinamento = treinamentoOptions.find((treinamentos) => treinamentos.id == selectedValueTreinamentos);

    if (treinamento === undefined) {
      treinamento = null;
    }
    if (treinamento != undefined) {
      treinamento = treinamento.nome;
    }

    // Criar um novo objeto de serviço com os dados atuais
    const novoServico = {
      servico: servicoSelecionado.servico,
      descricao: servicoSelecionado.descricao,
      quant: servicos.quant,
      valor: servicoSelecionado.valor,
      desconto: servicos.desconto,
      subtotal: servicos.subtotal,
      id_servico: valorServico,
      modelo: servicoSelecionado.modelo,
      area_id: area_id,
      periodo: selectedPeriod,
      treinamentoId: selectedValueTreinamentos,
      treinamento: treinamento
    };

    // Adicionar o novo serviço ao estado listaServicos
    setListaServicos([...listaServicos, novoServico]);

    // Atualizar o estado de produtos com base nos serviços
    setProdutos((prevProdutos) => {
      let novaQuantidade;
      let novoTotal;
      let notoTotalSemDesconto;
      //1° verificar se ja existe desconto e seja diferente de zero.
      //2° se desconto for diferente de zero somar o total novo com o totalsemDesconto existente.
      //3° gerar um valor total novo com desconto usando o valor total novo com o desconto existente.
      //4° verificar se está somando a quantidade.
      if (produtos.desconto != 0) {
        debugger;
        console.log("valor", servicoSelecionado.valor);
        console.log("subtotal", servicos.subtotal);
        novaQuantidade = prevProdutos.quantidade + 1;
        notoTotalSemDesconto = parseFloat(prevProdutos.totalSemDesconto) + parseFloat(servicos.subtotal);
        var valorDesconto = (produtos.desconto / 100) * notoTotalSemDesconto;
        novoTotal = parseFloat(notoTotalSemDesconto) - parseFloat(valorDesconto);
      } else if (produtos.desconto == 0) {
        novaQuantidade = prevProdutos.quantidade + 1;
        novoTotal = parseFloat(prevProdutos.totalSemDesconto) + parseFloat(servicos.subtotal);
        notoTotalSemDesconto = novoTotal;

        Pagamento.total = novoTotal;
        Pagamento.valorVista = novoTotal;
        Pagamento.formapagamento = formaPagamento;
      }




      //Variavel com o total sem desconto
      //produtos.totalSemDesconto
      //Variavel com o valor de desconto digitado
      //produtos.desconto

      return {
        ...prevProdutos,
        quantidade: novaQuantidade,
        total: novoTotal,
        totalSemDesconto: notoTotalSemDesconto
      };
    });

    // Limpar os campos do formulário de serviço
    setServicoSelecionado({});
    /////////////////////////
    setAreaId("");
    setValorServico("");
    setServicosDaApi([]);
    setSelectedValue('');
    setSelectedValueTreinamentos("");
    setTreinamentoOptions([]);
    setSelectedPeriod('Mensal');
    setServicos({
      servico: "",
      descricao: "",
      quant: 1,
      valor: 0,
      desconto: 0,
      subtotal: 0,
      modelo: "",
      periodo: ""
    });

    setServicoSelecionado({
      descricao: "",
      valor: 0,
      servico: "",
      area_id: "",
      id_servico: "",
      modelo: "",
      periodo: "Mensal"
    })

    //console.log("Pagamento", Pagamento);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.cliente = clienteSelecionado;
    //formData.periodo = selectedPeriod;
    //console.log("clienteselecionado", clienteSelecionado);

    // console.log("teste", e);
    // console.log("cliente", formData);
    // console.log("serviços", servicos);
    // console.log("produtos", produtos);
    // console.log("observacoes", observacoes);
    console.log("listaServicos", listaServicos);
    console.log("servicoSelecionado", servicoSelecionado);
    // console.log("pagamento", Pagamento);

    e.preventDefault();

    console.log("serviço", servicos);

    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    formData.data = parseISO(formData.data);
    if (formaPagamento === "parcelado") {
      Pagamento.dadosbancariosparcelado = dadosBancariosSelecionado;
      Pagamento.formapagamento = "parcelado";
    } else if (formaPagamento === "avista") {
      Pagamento.dadosbancarios = dadosBancariosSelecionado;
      Pagamento.formapagamento = "avista";
    }

    const obj = {
      formData,
      Pagamento,
      produtos,
      listaServicos,
      datasParceladas
    }

    console.log("obj", obj);

    await api.post('/orcamento', obj, headers)
      .then((response) => {
        setRetorno({
          type: 'success',
          mensagem: response.data.mensagem
        });
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });
  };

  const handleDescontoChange = (e) => {
    e.preventDefault();
    console.log("e", e.target.value);

    const { name, value } = e.target;

    console.log("name", name);
    console.log("value", value);

    if (value == 0) {
      console.log("value", value);
    }
    if (value < 0) {
      console.log("menor", value);
    }

    let valorDoDesconto;

    setProdutos((prevProdutos) => {
      console.log("produtos.totalSemDesconto", produtos.totalSemDesconto);
      console.log("produtos.total", produtos.total);

      console.log("prevProdutos.totalSemDesconto", prevProdutos.totalSemDesconto);
      console.log("prevProdutos.total", prevProdutos.total);
      //console.log("servicoSelecionado.valor", servicoSelecionado.valor);

      //console.log("prevServicos.valor", prevServicos.valor);
      if (value == 0 || value > 0) {
        let desconto =
          name === "desconto" ? value : parseFloat(prevProdutos.desconto);
        if (isNaN(desconto)) {
          // Se o desconto não for um número válido ou for negativo, mantenha os dados iguais
          valorDoDesconto = 0;
        }
        if (desconto === undefined) {
          desconto = 0;
        }

        if (desconto != 0) {
          valorDoDesconto = (desconto / 100) * produtos.totalSemDesconto;
          console.log("valorDoDesconto", valorDoDesconto);
          // console.log("prevProdutos.total", prevProdutos.total);
          // produtos.total = produtos.total - valorDoDesconto;
          // console.log("prevProdutos.total", prevProdutos.total);
          // prevProdutos.desconto = desconto;
          // console.log("prevProdutos.desconto", prevProdutos.desconto);

          //produtos.total = prevProdutos.total;
        }
        if (desconto == 0) {
          valorDoDesconto = 0;
        }

        var total = (produtos.totalSemDesconto - valorDoDesconto);

        Pagamento.totalSemDesconto = produtos.totalSemDesconto;
        Pagamento.total = total.toFixed(2);
        if (formaPagamento === 'avista') {
          Pagamento.datapagamento = Pagamento.datapagamento;
        } else if (formaPagamento === 'parcelado') {
          Pagamento.datapagamentoparcelado = Pagamento.datapagamentoparcelado;
        }







        //console.log("area_id", area_id);
        console.log("produtos", produtos);

        return {
          ...prevProdutos,
          [name]: value,
          total: total.toFixed(2),
        };
      } else {
        return {
          ...prevProdutos,
          [name]: value,
          total: total.toFixed(2),
        };
      }

    });
  };

  const [formaPagamento, setFormaPagamento] = useState("avista");

  const handleFormaPagamentoChange = (e) => {
    if (e.target.value === "avista") {
      debugger;
      setPagamento({
        formapagamento: formaPagamento,
        valorVista: Pagamento ? Pagamento.total : "",
        numeroparcela: 0,
        total: Pagamento ? Pagamento.total : "",
        valorParcela: 0
      });
      setDatasParceladas([]);
    }
    if (e.target.value === "parcelado") {
      setPagamento({
        formapagamento: formaPagamento,
        valorVista: 0,
        numeroparcela: 0,
        total: Pagamento ? Pagamento.total : "",
        valorParcela: 0,
        totalSemDesconto: Pagamento ? Pagamento.totalSemDesconto : "",
      });
    }
    setFormaPagamento(e.target.value);
  };

  const handleExcluirServico = (index) => {
    // Crie uma cópia da lista de serviços
    const novaListaServicos = [...listaServicos];

    // Remova o serviço pelo índice
    const servicoRemovido = novaListaServicos.splice(index, 1)[0];

    // Atualize o estado da lista de serviços
    setListaServicos(novaListaServicos);

    // Atualize o estado de produtos com base na remoção do serviço
    setProdutos((prevProdutos) => {

      console.log("produtos.desconto", produtos.desconto);
      var desconto = produtos.desconto;
      var totalAtualsemDesconto = parseFloat(prevProdutos.totalSemDesconto) - parseFloat(servicoRemovido.subtotal);
      var totalComDesconto;
      var valorDoDesconto;
      if (desconto > 0) {
        valorDoDesconto = (desconto / 100) * totalAtualsemDesconto;
        totalComDesconto = (totalAtualsemDesconto - valorDoDesconto);
      }
      if (desconto === 0 || desconto < 0) {
        totalComDesconto = totalAtualsemDesconto;
      }



      return {
        ...prevProdutos,
        quantidade: prevProdutos.quantidade - 1,
        totalSemDesconto: totalAtualsemDesconto,
        total: totalComDesconto,
        desconto: produtos.desconto,
      };
    });
    setPagamento({
      total: Pagamento ? Pagamento.total - servicoRemovido.subtotal : "",
    });

    Pagamento.total = Pagamento.total - servicoRemovido.subtotal;
  };

  const [observacoes, setObservacoes] = useState("");
  const [observacoesInternas, setObservacoesInternas] = useState("");
  const [clientes, setClientes] = useState("");
  const [servicosDaApi, setServicosDaApi] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [bancoOptions, setBancoOptions] = useState([]);
  const [treinamentoOptions, setTreinamentoOptions] = useState([]);

  const getServicos = async (areaid) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      const response = await api.get("/servicoarea/" + areaid, headers);
      if (response.data) {
        setServicosDaApi(response.data);
      } else {
        console.log("Erro: serviço não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro", err);
        setServicosDaApi([]);
      } else {
        console.log("Erro não encontrado");
      }
    }
  };

  const handlePeriodicidadeChange = (e) => {
    setServicos((prevServicos) => ({
      ...prevServicos,
      modelo: e.target.value,
    }));
    servicoSelecionado.modelo = e.target.value;
    setSelectedValue(e.target.value);
    if (e.target.value == "Avulso") {
      setSelectedPeriod("Avulso");
    }
    if (e.target.value == "Contrato") {
      setSelectedPeriod("Mensal");
    }
  }


  const handlePeriodChange = (e) => {
    setServicos((prevServicos) => ({
      ...prevServicos,
      periodo: e.target.value,
    }));
    servicoSelecionado.periodo = e.target.value;
    setSelectedPeriod(e.target.value);
  }

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getCliente = async (tcliente) => {
    try {
      const response = await api.post("/instituicaoTipoCliente", { tcliente }, headers);
      console.log("response.data", response.data);
      if (response.data) {
        setClientes(response.data);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro não encontrado");
      }
    }
  };





  useEffect(() => {
    const descontoEmValor = produtos.desconto;

    setProdutos((prevProdutos) => ({
      ...prevProdutos,
      total:
        (prevProdutos.totalSemDesconto || prevProdutos.total) - descontoEmValor,
    }));

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };



    const getArea = async () => {
      try {
        const response = await api.get("/area", headers);
        setAreaOptions(response.data);
      } catch (error) {
        console.error("Erro ao obter opções de vinculo2:", error);
      }
    };


    const getDadosBancarios = async () => {
      console.log("idEmpresa getDadosBancarios", idEmpresa);
      try {
        const response = await api.get("/banco/" + idEmpresa, headers);
        setBancoOptions(response.data);
      } catch (error) {
        console.error("Erro ao obter dados bancarios:", error);
      }
    };

    getDadosBancarios();
    //getCliente();
    getArea();
    getEmpresa();
    //getBanco();

    // console.log("banco", banco);
    // console.log("agencia", agencia);
    // console.log("conta", conta);
    // console.log("tipoconta", tipoconta);
    // console.log("idEmpresa", idEmpresa);

  }, []);

  const valoresPermitidos = ['43', '44', '45'];

  const mostrarInput = valoresPermitidos.includes(valorServico);

  const [selectedPeriod, setSelectedPeriod] = useState('Mensal');

  // const handlePeriodChange = (event) => {
  //   setSelectedPeriod(event.target.value);
  // };

  const [tipoCliente, setTipoCliente] = useState(null);

  const handleTipoClienteChange = e => {
    setTipoCliente(e.target.value);
    console.log("tipoCliente e.target.value", e.target.value);
    console.log("tipoCliente ", tipoCliente);
    getCliente(e.target.value);
  };

  const getAreaNameById = (id) => {
    const area = areaOptions.find((option) => option.id === Number(id));
    return area ? area.area : 'Área Desconhecida';
  };

  const [selectedArea, setSelectedArea] = useState("");
  const [selectedServico, setSelectedServico] = useState("");


  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container" style={{ marginLeft: "220px" }}>
          <h1>Gerar Orçamento</h1>
          <hr />
          {retorno.type === "error" ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{retorno.mensagem}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header bg-secondary text-white">
                    <h5 className="card-title">Dados gerais</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                          <label className="form-label">Tipo Cliente:</label>
                        </b>
                        <select
                          name="tipoCliente"
                          className="form-select"
                          onChange={handleTipoClienteChange}
                          value={tipoCliente}
                        >
                          <option value="">Selecione o tipo</option>
                          <option value="PJ">Pessoa Juridica</option>
                          <option value="PL">Profissional Liberal</option>
                        </select>
                      </div>
                      {clientes && clientes.length > 0 && (
                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                          <label htmlFor="cliente" className="form-label">
                            <b>
                              Cliente
                            </b>
                          </label>
                          <select
                            className="form-select"
                            id="cliente"
                            name="cliente"
                            value={clienteSelecionado}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Selecione</option>
                            {clientes.map((cliente) => (
                              <option key={cliente.id} value={cliente.id}>
                                {cliente.nome}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="vendedor" className="form-label">
                          Vendedor/Responsável
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="vendedor"
                          name="vendedor"
                          value={vendedor}
                          onChange={handleChange}
                          disabled
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="data" className="form-label">
                          Data
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="data"
                          name="data"
                          onChange={handleChange}
                          value={formData.data}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="aosCuidados" className="form-label">
                          Aos Cuidados de
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="aosCuidados"
                          name="aosCuidados"
                          value={formData.aosCuidados}
                          onChange={handleChange}
                          onKeyPress={(event) => handleKeyPress(event)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <label htmlFor="introducao" className="form-label">
                          Detalhes
                        </label>
                        <textarea
                          className="form-control"
                          id="introducao"
                          name="introducao"
                          rows="4"
                          value={formData.introducao}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Serviços</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <b>
                        <label for="inputArea" className="form-label">
                          Área*:
                        </label>
                      </b>
                      <select
                        id="inputArea"
                        className="form-select"
                        name="area_id"
                        onChange={handleAreaChange}
                        value={area_id}
                      >
                        <option value="">Selecione</option>
                        {areaOptions.map((opcao) => (
                          <option value={opcao.id}>{opcao.area}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <b><label className="form-label">Serviço</label></b>
                        <select
                          className="form-select"
                          id="servico"
                          name="servico"
                          value={valorServico}
                          onChange={handleServicosSelectedChange}
                        >
                          <option value="">Selecione</option>
                          {servicosDaApi.map((servico) => (
                            <option key={servico.id} value={servico.id}>
                              {servico.servico}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="inputDescricao" className="form-label">Descrição:</label>
                      </b>


                      {mostrarInput ? (
                        <input
                          type="text"
                          autoComplete="off"
                          name="descricao"
                          className="form-control"
                          value={servicoSelecionado.descricao}
                          onChange={handleServicosChange}
                        />
                      ) : (
                        <select
                          id="inputDescricao"
                          className="form-select"
                          name="descricao"
                          value={servicoSelecionado.descricao}
                          onChange={handleServicosChange}
                        >

                          <option value="">Selecione</option>
                          {valorServico === '32' && <>
                            <option value="Cálculo de Blindagem Sala de Raios X Intraoral">Cálculo de Blindagem Sala de Raios X Intraoral</option>
                            <option value="Cálculo de Blindagem Sala de Raios X Extraoral">Cálculo de Blindagem Sala de Raios X Extraoral</option>
                            <option value="Cálculo de Blindagem Sala de Raios X">Cálculo de Blindagem Sala de Raios X</option>
                            <option value="Cálculo de Blindagem Sala de Mamografia">Cálculo de Blindagem Sala de Mamografia</option>
                            <option value="Cálculo de Blindagem Sala de Hemodinâmica">Cálculo de Blindagem Sala de Hemodinâmica</option>
                            <option value="Cálculo de Blindagem Sala de Tomografia">Cálculo de Blindagem Sala de Tomografia</option>
                            <option value="Cálculo de Blindagem Sala de Densitometria Óssea">Cálculo de Blindagem Sala de Densitometria Óssea</option>
                            <option value="Cálculo de Blindagem Sala de Liptripsia">Cálculo de Blindagem Sala de Liptripsia</option>
                            <option value="Cálculo de Blindagem Sala de Arco Cirúrgico">Cálculo de Blindagem Sala de Arco Cirúrgico</option>
                            <option value="Plano de Proteção Radiológica e Memorial Descritivo">Plano de Proteção Radiológica e Memorial Descritivo</option>
                            <option value="Programa de Garantia da Qualidade - Raios X">Programa de Garantia da Qualidade - Raios X</option>
                            <option value="Programa de Garantia da Qualidade - Mamografia">Programa de Garantia da Qualidade - Mamografia</option>
                            <option value="Programa de Garantia da Qualidade - Tomografia">Programa de Garantia da Qualidade - Tomografia</option>
                            <option value="Programa de Garantia da Qualidade - Raios X Fluoroscopia">Programa de Garantia da Qualidade - Raios X Fluoroscopia</option>
                            <option value="Programa de Garantia da Qualidade - Raios X Intraoral">Programa de Garantia da Qualidade - Raios X Intraoral</option>
                            <option value="Memorial Descritivo">Memorial Descritivo</option>
                            <option value="Programa de Garantia da Qualidade - Ressonância Magnética Nuclear">Programa de Garantia da Qualidade - Ressonância Magnética Nuclear</option>
                            <option value="Programa de Garantia da Qualidade - Ultrassom">Programa de Garantia da Qualidade - Ultrassom</option>
                            <option value="Programa de Educação Continuada">Programa de Educação Continuada</option>
                          </>}
                          {valorServico === '35' && <>
                            <option value="Raios X Intraoral - bienal">Raios X Intraoral - bienal</option>
                            <option value="Raios X Extraoral - anual">Raios X Extraoral - anual</option>
                            <option value="Raios X - mensal">Raios X - mensal</option>
                            <option value="Raios X - semestral">Raios X - semestral</option>
                            <option value="Raios X - anual">Raios X - anual</option>
                            <option value="Mamografia - diario">Mamografia - diario</option>
                            <option value="Mamografia - mensal">Mamografia - mensal</option>
                            <option value="Mamografia - semestral">Mamografia - semestral</option>
                            <option value="Mamografia - anual">Mamografia - anual</option>
                            <option value="Tomografia - mensal">Tomografia - mensal</option>
                            <option value="Tomografia - semanal">Tomografia - semanal</option>
                            <option value="Tomografia - anual">Tomografia - anual</option>
                            <option value="Raios X Fluoroscopia - semanal">Raios X Fluoroscopia - semanal</option>
                            <option value="Raios X Fluoroscopia - mensal">Raios X Fluoroscopia - mensal</option>
                            <option value="Raios X Fluoroscopia - semestral">Raios X Fluoroscopia - semestral</option>
                            <option value="Raios X Fluoroscopia - anual">Raios X Fluoroscopia - anual</option>
                            <option value="Raios X Telecomandado - semanal">Raios X Telecomandado - semanal</option>
                            <option value="Raios X Telecomandado - semestral">Raios X Telecomandado - semestral</option>
                            <option value="Raios X Telecomandado - anual">Raios X Telecomandado - anual</option>
                            <option value="EPI - semestral">EPI - semestral</option>
                            <option value="EPI - anual">EPI - anual</option>
                            <option value="EPI - bienal">EPI - bienal</option>
                            <option value="Negatoscópio e/ou Monitor de Laudos - anual">Negatoscópio e/ou Monitor de Laudos - anual</option>
                            <option value="Sistema de Imagem Filme - Raios X - anual">Sistema de Imagem Filme - Raios X - anual</option>
                            <option value="Sistema de Imagem Filme - Mamografia - anual">Sistema de Imagem Filme - Mamografia - anual</option>
                            <option value="Sistema de Imagem CR - Raios X - anual">Sistema de Imagem CR - Raios X - anual</option>
                            <option value="Sistema de Imagem CR - Mamografia - anual">Sistema de Imagem CR - Mamografia - anual</option>
                            <option value="Densitometria óssea - mensal">Densitometria óssea - mensal</option>
                            <option value="Densitometria óssea - semestral">Densitometria óssea - semestral</option>
                            <option value="Densitometria óssea - anual">Densitometria óssea - anual</option>
                            <option value="Ultrassom - anual">Ultrassom - anual</option>
                            <option value="Ressonancia Magnética Nuclear - semanal">Ressonancia Magnética Nuclear - semanal</option>
                            <option value="Ressonancia Magnética Nuclear - anual">Ressonancia Magnética Nuclear - anual</option>
                          </>}
                          {valorServico === '33' && <>
                            <option value="Raios X Intraoral - Levantamento Radiométrico - quadrienal">Raios X Intraoral - Levantamento Radiométrico - quadrienal</option>
                            <option value="Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal">Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal</option>
                            <option value="Raios X Intraoral - Radiação Espalhada - quadrienal">Raios X Intraoral - Radiação Espalhada - quadrienal</option>
                            <option value="Raios X Extraoral - Levantamento Radiométrico - quadrienal">Raios X Extraoral - Levantamento Radiométrico - quadrienal</option>
                            <option value="Raios X - Levantamento Radiométrico - quadrienal">Raios X - Levantamento Radiométrico - quadrienal</option>
                            <option value="Raios X - Radiação de Fuga do cabeçote - quadrienal">Raios X - Radiação de Fuga do cabeçote - quadrienal</option>
                            <option value="Raios X - Radiação Espalhada - quadrienal">Raios X - Radiação Espalhada - quadrienal</option>
                            <option value="Mamografia - Levantamento Radiométrico - quadrienal">Mamografia - Levantamento Radiométrico - quadrienal</option>
                            <option value="Mamografia - Radiação de Fuga do cabeçote - quadrienal">Mamografia - Radiação de Fuga do cabeçote - quadrienal</option>
                            <option value="Tomografia - Levantamento Radiométrico - quadrienal">Tomografia - Levantamento Radiométrico - quadrienal</option>
                            <option value="Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal">Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal</option>
                            <option value="Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal">Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal</option>
                            <option value="Raios X Fluoroscopia - Radiação Espalhada - quadrienal">Raios X Fluoroscopia - Radiação Espalhada - quadrienal</option>
                          </>}
                          {valorServico === "48" && (
                            <>
                              <option value="EAD">
                                EAD
                              </option>
                              <option value="Presencial">
                                Presencial
                              </option>
                            </>
                          )}
                        </select>
                      )}
                    </div>

                    {treinamentoOptions && treinamentoOptions.length > 0 && (
                      <div className="col-md-4">
                        <b>
                          <label for="inputArea" className="form-label">
                            Selecione o Treinamento:
                          </label>
                        </b>
                        <select
                          id="inputArea"
                          className="form-select"
                          name="treinamentoPorArea"
                          onChange={handleChangeTreinamento}
                          value={selectedValueTreinamentos}
                        >
                          <option value="">Selecione</option>
                          {treinamentoOptions.map((opcao) => (
                            <option value={opcao.id}>{opcao.nome}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="col-md-2">
                      <div className="mb-2">
                        <b><label htmlFor="quant" className="form-label">Quantidade</label></b>
                        <input
                          type="number"
                          className="form-control"
                          id="quant"
                          name="quant"
                          value={servicos.quant}
                          onChange={handleServicosChange}
                          onKeyPress={(event) => handleKeyPress(event)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-2">
                        <b><label htmlFor="valor" className="form-label">Valor</label></b>
                        <input
                          type="number"
                          className="form-control"
                          id="valor"
                          name="valor"
                          value={servicoSelecionado.valor}
                          onChange={handleServicosChange}
                          onKeyPress={(event) => handleKeyPress(event)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                      <b><label htmlFor="subtotal" className="form-label">Subtotal</label></b>
                      <input
                        type="text"
                        className="form-control"
                        id="subtotal"
                        name="subtotal"
                        value={servicos.subtotal}
                        readOnly
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                      <b>
                        <label className="form-label">Modelo de Contratação:</label>
                      </b>
                      <select
                        className={`form-select`}
                        name="periodicidade"
                        onChange={handlePeriodicidadeChange}
                        value={selectedValue}
                      >
                        <option value="">Selecione</option>
                        <option value="Avulso">Avulso</option>
                        <option value="Contrato">Contrato</option>
                      </select>
                    </div>
                    <div className="row">
                      <div className="row">
                        <label htmlFor="vendedor" className="form-label">
                          <b>
                            Selecione um Período:
                          </b>
                        </label>
                        <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                          <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="Anual"
                            checked={selectedPeriod === 'Anual'}
                            onChange={handlePeriodChange}
                            disabled={selectedPeriod === "Avulso"}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Anual
                          </label>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                          <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="Semestral"
                            checked={selectedPeriod === 'Semestral'}
                            onChange={handlePeriodChange}
                            disabled={selectedPeriod === "Avulso"}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Semestral
                          </label>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                          <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                            value="Trimestral"
                            checked={selectedPeriod === 'Trimestral'}
                            onChange={handlePeriodChange}
                            disabled={selectedPeriod === "Avulso"}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault3">
                            Trimestral
                          </label>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                          <input
                            className="form-check-input me-2"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                            value="Mensal"
                            checked={selectedPeriod === 'Mensal'}
                            onChange={handlePeriodChange}
                            disabled={selectedPeriod === "Avulso"}

                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault4">
                            Mensal
                          </label>
                        </div>
                        {/* <div>
                          <p>Período selecionado: {selectedPeriod}</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handleAdicionarServico}
                    className="btn btn-primary"
                    type="button"
                  >
                    Adicionar Serviço
                  </button>
                  {listaServicos && listaServicos.length > 0 && (
                    <ul>
                      {listaServicos.map((servico, index) => (
                        <li key={index}>
                          Área: {getAreaNameById(servico.area_id)} - {" "}
                          Serviço: {servico.servico} - Descrição:{" "}
                          {servico.descricao} - Treinamento: {servico.treinamento} - Quantidade: {servico.quant} -
                          Valor: R${servico.valor} -
                          Subtotal: R${servico.subtotal} - Modelo: {servico.modelo} -
                          Período: {servico.periodo}
                          <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() => handleExcluirServico(index)}
                            type="button"
                          >
                            Excluir
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Total</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          htmlFor="quantidadeProdutos"
                          className="form-label"
                        >
                          Produtos
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="quantidadeProdutos"
                          name="quantidadeProdutos"
                          value={produtos.quantidade}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="descontoProdutos" className="form-label">
                          Desconto (%)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="desconto"
                          name="desconto"
                          value={produtos.desconto}
                          onKeyPress={(event) => handleKeyPress(event)}
                          onChange={handleDescontoChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="totalProdutos" className="form-label">
                          Total
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="totalProdutos"
                          name="totalProdutos"
                          value={produtos.total}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="totalProdutos" className="form-label">
                          Total Sem Desconto
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="totalProdutos"
                          name="totalProdutos"
                          value={produtos.totalSemDesconto}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* Card de Pagamento */}
                <div className="card">
                  <div className="card-header bg-secondary text-white">
                    <h5 className="card-title">Pagamento</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="pagamentoAVista"
                              name="formaPagamento"
                              value="avista"
                              checked={formaPagamento === "avista"}
                              onChange={handleFormaPagamentoChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pagamentoAVista"
                            >
                              À Vista
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="pagamentoParcelado"
                              name="formaPagamento"
                              value="parcelado"
                              checked={formaPagamento === "parcelado"}
                              onChange={handleFormaPagamentoChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="pagamentoParcelado"
                            >
                              Parcelado
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            {formaPagamento === 'avista' && produtos.total !== 0 && <>
                              <label>Valor Total a ser Pago a vista é R${Pagamento.total}</label>
                            </>}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            {formaPagamento === 'avista' && produtos.total !== 0 && <>
                              <label>Data de Pagamento</label>
                              <input
                                type="date"
                                className="form-control"
                                id="datapagamento"
                                name="datapagamento"
                                value={Pagamento.datapagamento}
                                onChange={handleDataPagamento}
                                required
                                onKeyPress={(event) => handleKeyPress(event)}
                              />
                            </>}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-check form-check-inline">
                            {formaPagamento === 'avista' && produtos.total !== 0 && <>
                              {bancoOptions && bancoOptions.length > 0 && (
                                <div>
                                  <label htmlFor="dadosbancarios" className="form-label">
                                    Dados Bancarios
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dadosbancarios"
                                    name="dadosbancarios"
                                    value={dadosBancariosSelecionado}
                                    onChange={handleDadosBancarios}
                                    required
                                  >
                                    <option value="">Selecione</option>
                                    {bancoOptions.map((banco) => (
                                      <option key={banco.id} value={banco.id}>
                                        {banco.banco}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            </>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-6">
                          <div className="form-check form-check-inline">
                            {formaPagamento === 'parcelado' && produtos.total !== 0 && <>
                              <div className="mb-6">
                                <b><label htmlFor="numeroparcela" className="form-label">Digite o numero de parcelas</label></b>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="numeroparcela"
                                  name="numeroparcela"
                                  value={Pagamento.numeroparcela}
                                  onChange={handlePagamentoParcela}
                                  onKeyPress={(event) => handleKeyPress(event)}
                                  required
                                />
                              </div>
                              <div className="md-6">
                                <b><label htmlFor="totalProdutos" className="form-label">Total a pagar em cada parcela é: </label></b>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="totalProdutos"
                                  name="totalProdutos"
                                  value={`R$${Pagamento.valorParcela}`}
                                  readOnly
                                />
                              </div>
                              {bancoOptions && bancoOptions.length > 0 && (
                                <div>
                                  <label htmlFor="dadosbancarios" className="form-label">
                                    Dados Bancarios
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dadosbancarios"
                                    name="dadosbancarios"
                                    value={dadosBancariosSelecionado}
                                    onChange={handleDadosBancarios}
                                    required
                                  >
                                    <option value="">Selecione</option>
                                    {bancoOptions.map((banco) => (
                                      <option key={banco.id} value={banco.id}>
                                        {banco.banco}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              <div className="mb-6">
                                <b><label htmlFor="datapagamento" className="form-label">Data de pagamento</label></b>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="datapagamento"
                                  name="datapagamento"
                                  value={Pagamento.datapagamentoparcelado}
                                  onChange={handleDataChange}
                                  onKeyPress={(event) => handleKeyPress(event)}
                                  required
                                />
                              </div>
                              <br></br>
                              <button
                                onClick={handleDatasParcelamento}
                                className="btn btn-primary"
                                type="button"
                              >
                                Gerar
                              </button>
                              <ul>
                                {datasParceladas.map((data, index) => (
                                  <li key={index}>{data.datapagamento + " - R$" + data.valorParcela}</li>
                                ))}
                              </ul>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* Card de Observações Internas */}
                <div className="card mb-12">
                  <div className="card-header bg-secondary text-white">
                    <h5 className="card-title">Observações Internas</h5>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label htmlFor="observacoesInternas" className="form-label">
                        Observações Internas
                      </label>
                      <textarea
                        className="form-control"
                        id="observacoesInternas"
                        name="observacoesInternas"
                        rows="3"
                        value={formData.observacoesInternas}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-12 mb-4 text-center">
                <Link to={"/orcamento"}>
                  <button type="button" className="btn btn-danger me-md-2">
                    Cancelar
                  </button>
                </Link>
                {retorno.type === "success" ? (
                  <Redirect
                    to={{
                      pathname: "/orcamento",
                      state: {
                        type: "success",
                        mensagem: retorno.mensagem,
                      },
                    }}
                  />
                ) : (
                  ""
                )}
                <button type="submit" className="btn btn-success me-md-2">
                  Cadastrar
                </button>
                <button type="button" onClick={handleGeneratePDF} className="btn btn-success">
                  Gerar PDF
                </button>
              </div>
            </div>
          </form>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Erro de Validação"
            ariaHideApp={false}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Erro de Validação</h5>
                <button type="button" className="btn-close" onClick={() => setIsModalOpen(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>{modalMessage}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(false)}>
                  Fechar
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </PageContent >
    </div >
  );
};
