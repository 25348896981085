import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link } from 'react-router-dom';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import axios from 'axios';

import localforage from 'localforage';

export const ViewOs = (props) => {

    const idEmpresa = localStorage.getItem("empresa");

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState('');
    const [tipo, setTipo] = useState('');
    const [envioTreinamento, setEnvioTreinamento] = useState('');




    const [clienteOptions, setClienteOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);

    const [dataVisita, setDataVisita] = useState('');
    const [prazo, setPrazo] = useState('');
    const [statusos, setStatusOs] = useState('');

    const [telaAtual, setTelaAtual] = useState('viewos');
    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);
    const [arquivonf, setArquivoNf] = useState('');
    const [nf, setNf] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [viewMostrar, setViewMostrar] = useState(null);
    const [statusCliente, setStatusCliente] = useState('');


    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    const handleChange = (e) => {
        e.preventDefault();
        setCliente(e.target.value);
    };

    const handleData = (date) => {
        console.log("date", date);
        setDatacriacao(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const handleDataVisita = (date) => {

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazo(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        setPrazo(date);
    };

    const handleSelectChange = (event) => {
        console.log("event.target.value", event.target.value);
        setStatusOs(event.target.value);
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setNf(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (nf !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', nf);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const VisualizarPDFTodos = async () => {
        const pdfUrls = [nf, ...servicos.map(servico => servico.endImagem)];
        console.log("pdfUrls", pdfUrls);
        if (pdfUrls !== null && pdfUrls.length > 0) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', pdfUrls);

                // Abra a nova aba
                const novaAba = window.open('/viewvariospdfs/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                console.log("if que verifica arquivo", e);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(e);
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };

    const verificaPdfVisualizar = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                try {
                    // Armazene os dados no localforage
                    await localforage.setItem('pdfData', e);

                    // Abra a nova aba
                    const novaAba = window.open('/viewpdf/', '_blank');
                    novaAba.focus();
                } catch (error) {
                    console.error('Erro ao armazenar os dados no localforage:', error);
                }
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };



    useEffect(() => {

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getOs = async () => {


            await api.get("/agendaos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response);
                        setCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setTipo(response.data.tipo);
                        if (response.data.tipo === 'treinamento') {
                            if (response.data.envioTreinamento === 1) {
                                setEnvioTreinamento("Email Enviado com sucesso!");
                            }
                            if (response.data.envioTreinamento !== 1) {
                                setEnvioTreinamento("Email Não Enviado!");
                            }
                        }

                        setDataVisita(response.data.datavisita);
                        setPrazo(response.data.prazo);
                        setStatusOs(response.data.statusos);

                        verificaPdf(response.data.nfImagem);

                        setStatusCliente(response.data.statusCliente);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnicoPorEmpresa", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();

        console.log("serviços", servicos);

    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Visualizar OS</h1>
                <hr />
                <form className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" name="cliente" value={cliente} onChange={handleChange} disabled>
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                <b>Criador da OS</b>
                                            </label>
                                            <input type="text" name="criadordaos" className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                <b>Tipo da OS:</b>
                                            </label>
                                            <input
                                                type="text"
                                                name="tipo"
                                                className="form-control"
                                                value={tipo === 'servico' ? 'serviço' : tipo}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                <b>Data da criação do Orçamento</b>
                                            </label>
                                            <input type="date" name="datacriacao" className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                <b>Data da visita</b>
                                            </label>
                                            <input type="date" name="dataVisita" className="form-control" value={dataVisita} onChange={(e) => handleDataVisita(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="prazo" className="form-label">
                                                <b>Prazo da Os</b>
                                            </label>
                                            <input type="date" name="prazo" className="form-control" value={prazo} onChange={(e) => handleDataPrazo(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <label for="tecnico" className="form-label">
                                                <b>Técnicos*:</b>
                                            </label>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}
                                                disabled
                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" value={statusos} onChange={handleSelectChange} disabled>
                                                <option value="">Selecione</option>
                                                <option value="Agendado">Agendado</option>
                                                {tipo === 'servico' && (
                                                    <option value="Aguardando agendamento">Aguardando Agendamento</option>
                                                )}
                                                <option value="Aguardando faturamento">Aguardando Faturamento</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Aguardando Envio">Aguardando Envio</option>
                                                )}
                                                <option value="Cancelado">Cancelado</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Enviado">Enviado</option>
                                                )}
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em correção">Em Correção</option>
                                                <option value="em revisão">Em Revisão</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="emitir certificado">Emitir certificado</option>
                                                )}
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                        </div>
                                        {tipo === 'treinamento' && (
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                <label htmlFor="detalhes" className="form-label">
                                                    <b>Email do treinamento Enviado</b>
                                                </label>
                                                <input type="text" name="envioTreinamento" className="form-control" value={envioTreinamento} onChange={text => setEnvioTreinamento(text.target.value)} disabled ></input>
                                            </div>
                                        )}
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Visualizar Todos PDFs:</label>
                                            </b>
                                            <br />


                                            <button type='button' onClick={() => VisualizarPDFTodos()} className="btn btn-success">
                                                Visualizar PDFs
                                            </button>

                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                            {/* <label for="nf" className="form-label">Selecione uma foto/pdf do NF</label>
                                            <input className="form-control" type="file" name="nf" id="nf" onChange={veirificaTipoArquivo} /><br /><br /> */}

                                            {viewMostrarpdf === 1 && <>
                                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                                    Visualizar NF
                                                </button>
                                            </>}

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                <b>Detalhes</b>
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title"><b>Serviços</b></h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {servicos.length > 0 &&
                                            servicos.map((item) => {
                                                if (item.idservico !== 44 && item.idservico !== 43 && item.idservico !== 45) {
                                                    return (
                                                        <div className="col-md-12" key={item.id}>
                                                            <div className="card mb-4">
                                                                <div className="card-body">
                                                                    <h5 className="card-title">Área: {item.area}</h5>
                                                                    <h6 className="card-subtitle mb-2 text-muted">Serviço: {item.servico}</h6>
                                                                    <p className="card-text">Descrição: {item.descricao}</p>
                                                                    {/* <p>Status do serviço: {item.statusServico}</p> */}
                                                                    {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                        <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                                    ) : (
                                                                        <div>
                                                                            {item.idequipamento !== null ? (
                                                                                <>

                                                                                    <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                                    <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                                    <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                                    <p className="card-text">Modelo: {item.modelo}</p>
                                                                                    <p className="card-text">N° de série: {item.numserie}</p>
                                                                                </>
                                                                            ) : (
                                                                                <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {item.treinamento_id != null && (
                                                                        <p className="card-title">Treinamento: {item.nomeTreinamento}</p>
                                                                    )}
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 form-outline mb-6 ">
                                                                            <p>Status do serviço:</p>
                                                                            <div className="col-md-12">
                                                                                <div className="btn-group me-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`reprovado_${item.id}`}
                                                                                        name={`status_${item.id}`}
                                                                                        value="reprovado"
                                                                                        checked={item.statusServico === 'reprovado'}
                                                                                        // onChange={() => handleStatusChange(item.id, 'reprovado')}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor={`reprovado_${item.id}`} className="mr-3">Reprovado</label>
                                                                                </div>
                                                                                <div className="btn-group me-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`aprovado_${item.id}`}
                                                                                        name={`status_${item.id}`}
                                                                                        value="aprovado"
                                                                                        checked={item.statusServico === 'aprovado'}
                                                                                        //onChange={() => handleStatusChange(item.id, 'aprovado')}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor={`aprovado_${item.id}`} className="mr-3">Aprovado</label>
                                                                                </div>
                                                                                <div className="btn-group me-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`revisar_${item.id}`}
                                                                                        name={`status_${item.id}`}
                                                                                        value="revisar"
                                                                                        checked={item.statusServico === 'revisar'}
                                                                                        //onChange={() => handleStatusChange(item.id, 'revisar')}
                                                                                        disabled
                                                                                    />
                                                                                    <label htmlFor={`revisar_${item.id}`} className="mr-3">Revisar</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br></br>
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col-lg-3 col-md-3 col-sm-3 form-outline mb-4">
                                                                                <div className="d-flex">

                                                                                    {item.pdf_parte2 != null ? (
                                                                                        <button type="button" className="btn btn-success p-2 me-md-2" onClick={() => verificaPdfVisualizar(item.endImagem)}>Visualizar Serviço</button>
                                                                                    ) : (
                                                                                        <button type="button" className="btn btn-success p-2 me-md-2">SEM PDF</button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                                                <label htmlFor="comentarionovo" className="form-label">
                                                                                    <b>
                                                                                        Histórico
                                                                                    </b>
                                                                                </label>
                                                                                <div className="d-flex">
                                                                                    <textarea
                                                                                        disabled
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        value={item.comentarioServico}
                                                                                        id="comentarionovo"
                                                                                        name="comentarionovo"
                                                                                        rows="3"
                                                                                    ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />
                            <Link to={"/os"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}