import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import axios from 'axios';

import localforage from 'localforage';

export const FaturamentoOS = (props) => {

    const idEmpresa = localStorage.getItem("empresa");
    const [telaAtual, setTelaAtual] = useState('faturamentoos');

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [prazoentrega, setPrazoentrega] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState([]);
    const [status, setStatus] = useState('');

    const [clienteOptions, setClienteOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);


    const [areaId, setAreaId] = useState(1);
    const [servicosDaApi, setServicosDaApi] = useState([]);
    const [servico, setServico] = useState("");
    const [descricao, setDescricao] = useState("");

    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});
    const [equipamentos, setEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [listaServicosOs, setListaServicosOs] = useState([]);

    const [dataVisita, setDataVisita] = useState('');
    const [statusCliente, setStatusCliente] = useState('');


    const [id] = useState(props.match.params.id);

    const [todosComPdfParte2, setTodosComPdfParte2] = useState(false);

    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);

    const [viewMostrar, setViewMostrar] = useState(null);
    const [arquivonf, setArquivoNf] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [nf, setNf] = useState('');
    const [image, setImage] = useState('');

    const [email, setEmail] = useState('');
    //console.log("id Visualizar Area", id);

    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setCliente(e.target.value);
    // };

    const handleData = (date) => {
        console.log("date", date);
        setDatacriacao(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const handleAreaChange = (e) => {
        getServicos(e.target.value);
        setAreaId(e.target.value);
    };

    const handleServicoChange = (e) => {
        setServico(e.target.value);
    };

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleEquipamentoChange = (e) => {
        setEquipamento(e.target.value);
        getEquipamentoById(e.target.value);
    };

    const handleClienteChange = (e) => {
        setCliente(e.target.value);
        getEquipamentoInstituicao(e.target.value);
    };

    const handleCliente = (e) => {
        console.log("e handleCliente", e);
        setCliente(e);
        getEquipamentoInstituicao(e);
    };

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleDataVisita = (date) => {
        console.log("date", date);

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazoentrega(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        console.log("date", date);
        setPrazoentrega(date);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const getEquipamentoInstituicao = async (e) => {
        try {
            const response = await api.get("/equipamento/" + e, headers);
            if (response.data) {
                console.log("Equipamentos", response.data);
                setEquipamentos(response.data);
            } else {
                console.log("Erro: Equipamentos não encontrados!");
            }
        } catch (error) {
            console.log("Erro ao buscar equipamentos:", error.message);
        }
    };



    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const getServicos = async (areaid) => {
        try {
            const response = await api.get("/servicoarea/" + areaid, headers);
            if (response.data) {
                setServicosDaApi(response.data);
            } else {
                console.log("Erro: serviço não encontrado !");
            }
        } catch (err) {
            if (err) {
                console.log("Erro", err);
                setServicosDaApi([]);
            } else {
                console.log("Erro não encontrado");
            }
        }
    };

    const getEquipamentoById = async (e) => {
        try {
            const response = await api.get("/equipamento/selecionar/" + e, headers);
            console.log("response equipamento", response.data);
            setEquipamentoSelecionado(response.data);
        } catch (error) {
            console.error("Erro ao obter opções de equipamento:", error);
        }
    };

    const handleAdicionarServico = async (e) => {
        e.preventDefault();

        const novoServicoOs = {
            descricao: descricao,
            id_servico: servico,
            servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
            area_id: areaId,
            area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
            equipamento_id: equipamento ? equipamento : "",
            equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento : "",
            os_id: id
        };

        await api.post('/ostemservico', novoServicoOs, headers)
            .then((response) => {
                // setRetorno({
                //     type: 'success',
                //     mensagem: response.data.mensagem
                // });
                fetchData();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

        console.log("novoServicoOs", novoServicoOs);
    };

    const handleExcluirServico = (index) => {
        // Crie uma cópia da lista de serviços
        const novaListaServicos = [...listaServicosOs];

        // Remova o serviço pelo índice
        const servicoRemovido = novaListaServicos.splice(index, 1)[0];

        // Atualize o estado da lista de serviços
        setListaServicosOs(novaListaServicos);
    };

    const SalvarFinanceiroOs = async (e) => {
        e.preventDefault();

        var status = "Aguardando faturamento";


        await api.put('/osatualizarstatus', { status, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const SalvarCorrecaoOs = async (e) => {
        e.preventDefault();

        console.log("status", status);
        console.log("id", id);
        console.log("nf", nf);
        console.log("arquivonf", arquivonf);

        var headersm = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put('/osnf', { status, id, nf, arquivonf, servicos, email }, headersm)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

    };

    const InsereNfOs = async (e) => {
        e.preventDefault();

        console.log("status", status);
        console.log("id", id);
        console.log("nf", nf);
        console.log("arquivonf", arquivonf);

        var headersm = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put('/osnfInsere', { status, id, nf, arquivonf, servicos, email }, headersm)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

    };



    const handleButtonClickEquipamento = async (statusEquipamento, nequipamento) => {

        console.log('Botão clicado!', statusEquipamento);

        var statusEqui;
        var idcliente;

        if (statusEquipamento === "inativo") {
            statusEqui = "ativo";
        }
        if (statusEquipamento === "ativo") {
            statusEqui = "inativo";
        }

        idcliente = cliente;


        await api.put('/ostemservicostatus', { statusEqui, idcliente, id, nequipamento }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    const handleButtonClickCliente = async () => {
        //e.preventDefault();

        console.log('Botão clicado!');

        var statusCli;
        var idcliente;

        if (statusCliente === "inativo") {
            statusCli = "ativo";
        }
        if (statusCliente === "ativo") {
            statusCli = "inativo";
        }

        idcliente = cliente;

        await api.put('/ostemservicostatus', { statusCli, idcliente, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    // var todosComPdfParte2;


    const fetchData = async () => {

        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response);
                        console.log("response.data.status", response.data.status);
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);
                        verificaPdf(response.data.nfImagem);

                        setEmail(response.data.emailCliente);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();

        console.log("status", status);
    };





    useEffect(() => {



        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response);
                        console.log("response.data.status", response.data.status);
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);
                        verificaPdf(response.data.nfImagem);

                        setEmail(response.data.emailCliente);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnicoPorEmpresa", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();
        getServicos(areaId);

        console.log("serviços", servicos);
        console.log("status", status);

    }, [id]);

    useEffect(() => {
        // Verifica se nf não é null
        const hasPdfParte2 = nf !== null && nf !== undefined; // Verifica se nf não é null ou undefined
        setTodosComPdfParte2(hasPdfParte2);
    }, [nf]);

    const handleButtonClickStatusServico = async (statusServico, idservico) => {

        console.log('Botão clicado!', statusServico, idservico);

        var statusServ;
        var idcliente;

        if (statusServico === "aprovado") {
            statusServ = "reprovado";
        }
        if (statusServico === "reprovado") {
            statusServ = "aprovado";
        }

        idcliente = cliente;


        await api.put('/ostemservicostatus', { idcliente, id, statusServ, idservico }, headers)
            .then((response) => {
                // setRetorno({
                //     type: 'success',
                //     mensagem: response.data.mensagem
                // });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                console.log("if que verifica arquivo", e);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(e);
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivoNf('pdf');
                setNf(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setNf(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (nf !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', nf);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const handlePagoChange = (event, itemId) => {
        setServicos((prevServicos) =>
            prevServicos.map((item) =>
                item.id === itemId ? { ...item, pago: event.target.checked } : item
            )
        );
    };


    //todosComPdfParte2 = servicos.every((item) => item.pdf_parte2 !== null);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Faturamento OS</h1>
                <hr />
                {retorno.type === "error" ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        <strong>{retorno.mensagem}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                ) : (
                    ""
                )}
                <form className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" disabled name="cliente" value={cliente} onChange={handleClienteChange} >
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                Criador da OS
                                            </label>
                                            <input type="text" name="criadordaos" disabled className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)}  ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da criação do Orçamento
                                            </label>
                                            <input type="date" name="datacriacao" disabled className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da visita
                                            </label>
                                            <input type="date" name="dataVisita" disabled className="form-control" value={dataVisita} onChange={(e) => handleDataVisita(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="prazo" className="form-label">
                                                Prazo da Os
                                            </label>
                                            <input type="date" name="prazo" disabled className="form-control" value={prazoentrega} onChange={(e) => handleDataPrazo(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <label for="tecnico" className="form-label">
                                                Técnicos*:
                                            </label>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}
                                                disabled
                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" disabled value={status} onChange={handleSelectChange}>
                                                <option value="Agendado">Agendado</option>
                                                <option value="Aguardando Agendamento">Aguardando Agendamento</option>
                                                <option value="Cancelado">Cancelado</option>
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em revisão">Em Revisão</option>
                                                <option value="Aguardando faturamento">Aguardando Faturamento</option>
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                            <label for="nf" className="form-label">Selecione uma foto/pdf do NF</label>
                                            <input className="form-control" type="file" name="nf" id="nf" onChange={veirificaTipoArquivo} /><br /><br />

                                            {viewMostrarpdf === 1 && <>
                                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                                    Visualizar PDF
                                                </button>
                                            </>}

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                Detalhes
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Serviços</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {servicos.length > 0 && (
                                            <>
                                                {servicos.map((item) => {
                                                    if (item.idservico !== 44 && item.idservico !== 43 && item.idservico !== 45) {
                                                        const isRed = item.criadoaposorcamento === 1;
                                                        return (
                                                            <div className="col-md-4" key={item.id}>
                                                                <div className="card mb-4">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Área: {item.area}</h5>
                                                                        <h6 className="card-subtitle mb-2 text-muted">Serviço: {item.servico}</h6>
                                                                        <p>Status do serviço: {item.statusServico}</p>
                                                                        <p className="card-text">Descrição: {item.descricao}</p>
                                                                        {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                            <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                                        ) : (
                                                                            <div>
                                                                                {item.idequipamento !== null ? (
                                                                                    <>

                                                                                        <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                                        <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                                        <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                                        <p className="card-text">Modelo: {item.modelo}</p>
                                                                                        <p className="card-text">N° de série: {item.numserie}</p>
                                                                                    </>
                                                                                ) : (
                                                                                    <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        {/* <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 form-outline mb-4">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <button type="button" className="btn btn-primary p-2 me-md-2">Visualizar</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <>
                                                                            <hr />
                                                                            <h5>Selecione qual serviço já foi Pago</h5>
                                                                            <i className={`fas fa-sack-dollar ${isRed ? 'text-danger' : 'text-success'}`} style={{ marginLeft: '10px' }}></i>
                                                                            <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={item.pago || false}
                                                                                    onChange={(event) => handlePagoChange(event, item.id)}
                                                                                    disabled={item.pago === 1}
                                                                                />
                                                                                <label style={{ marginLeft: '5px' }}>Pago</label>
                                                                            </div>

                                                                        </>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />
                            <Link to={"/os"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/os',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                            {todosComPdfParte2 ? (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-success me-md-2"
                                        onClick={SalvarCorrecaoOs}
                                    >
                                        Enviar para o Cliente
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-warning me-md-2"
                                        onClick={InsereNfOs}
                                    >
                                        Salvar NF
                                    </button>
                                </>


                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                    disabled={true}
                                >
                                    Enviar para o Cliente
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}