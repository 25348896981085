import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const statusCores = {
  'Agendado': 'rgba(75,192,192,1)',
  'Aguardando agendamento': 'rgba(54,162,235,1)',
  'Cancelado': 'rgba(255,99,132,1)',
  'em execucao': 'rgba(153,102,255,1)',
  'em correção': 'rgba(255,159,64,1)',
  'em revisão': 'rgba(255,205,86,1)',
  'Aguardando faturamento': 'rgba(201,203,207,1)',
  'finalizado': 'rgba(100,181,246,1)'
};

const gerarDatasets = (dados, statusCores) => {
  return Object.keys(statusCores).map(status => ({
    label: status,
    backgroundColor: statusCores[status],
    borderColor: statusCores[status],
    borderWidth: 1,
    data: dados.map(dado => dado[status] || 0)
  }));
};

const BarChartOs = ({ dados }) => {
  console.log("dados", dados);

  const data = {
    labels: dados.map(dado => dado.mesDescricao),
    datasets: gerarDatasets(dados, statusCores)
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Status Mensal',
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChartOs;