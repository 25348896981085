import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link } from 'react-router-dom';

export const ViewArea = (props) => {

    const [area, setArea] = useState('');
    const [descricao, setDescricao] = useState('');
    const [sigla, setSigla] = useState('');

    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    useEffect(() => {
        const getArea = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
    
            await api.get("/area/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        setArea(response.data.area);
                        setDescricao(response.data.descricao);
                        setSigla(response.data.sigla);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }
    
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getArea();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Visualizar Area</h1>
                <form className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Area:</label>
                        </b>
                        <input type="text" name="type" className="form-control" value={area} onChange={text => setArea(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Descrição:</label>
                        </b>
                        <input type="text" name="descricao" className="form-control" value={descricao} onChange={text => setDescricao(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Sigla:</label>
                        </b>
                        <input type="text" name="sigla" className="form-control" value={sigla} onChange={text => setSigla(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-12 mb-4 text-center">
                        <Link to={"/area"}>
                            <button type="button" className="btn btn-success">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}